import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import Modal from 'react-modal';
import Icon from '@mdi/react';
import { mdiBalloon } from '@mdi/js';
import $ from 'jquery';
import './styles/balloon.css';

import {
  popBalloon,
  completeDonation,
  balloonPopInProcess,
  setCookie,
  getCookie,
  setAreYouSure,
  setOutOfTime
} from '../actions';
import Paypal from './payment/Paypal';
import './styles/balloon.css';
import balloonReducer from '../reducers/balloonReducer';

let timerInterval;

Modal.setAppElement('#root');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    // maxHeight: 'calc(100vh - 210px)',
    overflow: 'auto',
    width: '35%',
    minWidth: '220px',
    maxHeight: '75%'
  }
};

class Balloon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // balloon: this.props.balloon,
      // group: this.props.group,
      showPaypal: false,
      random: 0,
      randomId: 0
      // balloonOpened: false
      // outOfTime: false
      // pageX: null,
      // pageY: null
      // areYouSure: this.props.areYouSure
    };
  }

  componentDidMount() {
    process.env.NODE_ENV === 'development' &&
      console.log('BALLOOOOON MOUNTEDDDD');
    const random = Math.random();
    const randomId = `id-${Math.ceil(Math.random() * 10000000)}-${
      this.props.balloon.color
    }`;
    this.setState({ random, randomId });
  }

  // componentWillReceiveProps({ balloon }) {
  //   this.setState({ balloon });
  // }

  openModal = async e => {
    process.env.NODE_ENV === 'development' && console.log(e.pageX);
    process.env.NODE_ENV === 'development' && console.log(e.pageY);
    // this.setState({ balloonOpened: true });
    // this.setState({ pageX: e.pageX, pageY: e.pageY });
    await this.props.balloonPopInProcess(
      this.props.group,
      this.props.balloon,
      true
    );
    this.setState({ showPaypal: true });
  };

  afterOpenModal = () => {
    let duration = 60 * (process.env.NODE_ENV === 'production' ? 10 : 0.5);
    let timer = duration;
    let minutes;
    let seconds;
    // setTimeout(() => {
    process.env.NODE_ENV === 'development' && console.log('again');
    const timerEl = document.getElementById(
      `balloon-${this.state.randomId}-group${this.props.group}-timer`
    );
    if (timerEl) {
      process.env.NODE_ENV === 'development' && console.log(timerEl);
      timerInterval = setInterval(() => {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;
        process.env.NODE_ENV === 'development' && console.log(minutes, seconds);
        timerEl.innerHTML = `${minutes}:${seconds}`;

        if (
          !document.getElementById(
            `balloon-${this.state.randomId}-group${this.props.group}-timer`
          )
        ) {
          clearInterval(timerInterval);
          // this.props.setOutOfTime(null);
        }

        if (this.props.donated) {
          // this.props.setOutOfTime(null);
          clearInterval(timerInterval);
          timerEl.innerHTML = '';
          timerEl.style.height = 0;
        }

        if (--timer < 0) {
          // timer = duration;
          process.env.NODE_ENV === 'development' &&
            console.log('SETTING COOKIE');
          this.props.setCookie('popStatus', false, 1);
          // this.props.setOutOfTime(null);
          clearInterval(timerInterval);
        }
      }, 1000);
      // this.setState({ timerInterval });
    }
    // }, 500);
    // timerInterval();
  };

  // afterOpenModal = () => {
  //   // references are now sync'd and can be accessed.
  //   // subtitle.style.color = '#f00';
  //   process.env.NODE_ENV === "development" && console.log( 'after open modal');
  //   subtitle.style.color = '#f00';
  // };

  closeModalFinal = async () => {
    this.setState({ showPaypal: false });
    this.props.setAreYouSure(false);
    await this.props.balloonPopInProcess(
      this.props.group,
      this.props.balloon,
      false,
      false,
      true,
      true
    );
  };

  closeModal = async () => {
    process.env.NODE_ENV === 'development' &&
      console.log('THIS CLOSE MODAL IS RUNNING');
    if (!this.props.donated && !this.props.areYouSure) {
      this.props.setAreYouSure(true);
    } else if (!this.props.donated) {
      this.setState({ showPaypal: false });
      this.props.setAreYouSure(false);
      await this.props.balloonPopInProcess(
        this.props.group,
        this.props.balloon,
        false,
        false,
        true,
        true
      );
      process.env.NODE_ENV === 'development' && console.log('SETTING COOKIE');
      await this.props.setCookie('popStatus', false, 1);
    } else {
      this.setState({ showPaypal: false });
      await this.props.setAreYouSure(false);
      await this.props.balloonPopInProcess(
        this.props.group,
        this.props.balloon,
        false,
        true,
        true,
        true
      );
      await this.popBalloon();
      // this.props.setOutOfTime(null);
    }
  };

  popBalloon = async e => {
    process.env.NODE_ENV === 'development' &&
      console.log('NOW WE ENTER POP BALLOOOON');
    document.getElementById('audio').play();
    process.env.NODE_ENV === 'development' && console.log('NOWWWWWWWWWWWWWW');
    console.log(this.state.randomId);
    this.props.balloonPopAnimation(
      this.props.group,
      this.props.balloon,
      this.state.randomId
    );
    // this.props.balloonPopAnimation();
    // // Shim with setTimeout fallback

    // const canvas = document.getElementById('balloon-pop-canvas');
    // const ctx = canvas.getContext('2d');

    // let laX = this.state.pageX;
    // let laY = this.state.pageY;
    // let W = canvas.width;
    // let H = canvas.height;
    // // Let's set our gravity
    // let gravity = 0.3;

    // // Time to write a neat constructor for our
    // // particles.
    // // Lets initialize a random color to use for
    // // our particles and also define the particle
    // // count.
    // let particle_count = parseInt(Math.random() * 1000);
    // let particles = [];

    // // let colors = ['#ffff35', '#fe5e31'];
    // // let random_color = colors[Math.floor(Math.random() * colors.length)];
    // let random_color = this.state.balloon.color;

    // function Particle() {
    //   this.radius = parseInt(Math.random() * 10);
    //   this.x = laX;
    //   this.y = laY;

    //   this.color = random_color;

    //   // Random Initial Velocities
    //   this.vx = Math.random() * 20 - 10;
    //   // vy should be negative initially
    //   // then only will it move upwards first
    //   // and then later come downwards when our
    //   // gravity is added to it.
    //   this.vy = Math.random() * -14 - 1;

    //   // Finally, the function to draw
    //   // our particle
    //   this.draw = function () {
    //     ctx.fillStyle = this.color;

    //     ctx.beginPath();

    //     ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
    //     ctx.fill();

    //     ctx.closePath();
    //   };
    // }

    // // Now lets quickly create our particle
    // // objects and store them in particles array
    // for (let i = 0; i < particle_count; i++) {
    //   let particle = new Particle();
    //   particles.push(particle);
    // }

    // // Finally, writing down the code to animate!
    // (function renderFrame() {
    //   requestAnimationFrame(renderFrame);

    //   // Clearing screen to prevent trails
    //   ctx.clearRect(0, 0, W, H);

    //   particles.forEach(function (particle) {
    //     // The particles simply go upwards
    //     // It MUST come down, so lets apply gravity
    //     particle.vy += gravity;

    //     // Adding velocity to x and y axis
    //     particle.x += particle.vx;
    //     particle.y += particle.vy;

    //     // We're almost done! All we need to do now
    //     // is to reposition the particles as soon
    //     // as they move off the canvas.
    //     // We'll also need to re-set the velocities

    //     particle.draw();
    //   });
    // })();
    await this.props.completeDonation();
    process.env.NODE_ENV === 'development' &&
      console.log('GOING TO POP THE BALLOON NOW');
    await this.props.popBalloon(this.props.group);
    process.env.NODE_ENV === 'development' && console.log('done');
  };

  // updateBalloonSize() {
  //   let h = window.innerHeight;
  //   document.getElementsByClassName('balloon').height = h;
  //   document.getElementsByClassName('balloon').width = h;
  // }

  setBalloonSettings = () => {
    let looks = {
      // color: 'white',
      // backgroundColor: this.state.balloon.color,
      opacity: 1
      // border: '0.5px solid black',
      // width: '60px',
      // height: '60px',
      // borderRadius: '100%',
      // fontSize: '0.9em',
      // textAlign: 'center',
      // verticalAlign: 'middle',
      // lineHeight: '30px'
    };
    let disabled = false;
    if (this.props.balloon.beingPoppedNow) {
      looks.opacity = 0.2;
      // looks.border = '0px';
      disabled = true;
    }
    if (this.props.balloon.popped) {
      looks.color = this.props.balloon.color;
      looks.backgroundColor = 'aliceblue';
      looks.opacity = 1;
      // looks.border = '0px';
      disabled = true;
    }
    return { disabled, looks };
  };

  // renderPaypalContent = () => {
  //   let outOfTime = false;
  //   if (this.props.outOfTime) {
  //     if (
  //       this.props.outOfTime.balloon.dollars === this.props.balloon.dollars &&
  //       this.props.outOfTime.group === this.props.group
  //     ) {
  //       if (!this.props.donated) {
  //         console.log('setting out of time to true');
  //         outOfTime = true;
  //         // this.props.setCookie('popStatus', false, 1);
  //       }
  //     }
  //   }
  //   return (
  //     <>
  //       <Paypal
  //         dollars={this.props.balloon.dollars}
  //         groupId={this.props.group}
  //         color={this.props.balloon.color}
  //         outOfTime={outOfTime}
  //       />
  //     </>
  //   );
  // {this.props.outOfTime ? (
  //   this.props.outOfTime.balloon.dollars ===
  //     this.props.balloon.dollars &&
  //   this.props.outOfTime.group === this.props.group ? (
  //     <div>You're out of time :(</div>
  //   ) : (
  //     <Paypal
  //       dollars={this.props.balloon.dollars}
  //       groupId={this.props.group}
  //       color={this.props.balloon.color}
  //     />
  //   )
  // ) : (
  //   <>
  //     <Paypal
  //       dollars={this.props.balloon.dollars}
  //       groupId={this.props.group}
  //       color={this.props.balloon.color}
  //     />
  //   </>
  // )}
  // };

  afterCloseModal = async () => {
    await this.props.setOutOfTime(null);
    clearInterval(timerInterval);
    // const balloonEl = document.getElementById(
    //   `balloon-${this.props.balloon.dollars}-group${this.props.group}-timer`
    // );
    // if (balloonEl) {
    //   balloonEl.style.width = `${this.props.balloon.randomSize * 75 + 20}px`;
    // }
    // this.setState({ balloonOpened: false });
  };

  render() {
    const { showPaypal } = this.state;
    let { disabled, looks } = this.setBalloonSettings();
    let popStatus = !disabled;
    if (this.props.popStatus === false) {
      popStatus = false;
    }
    let divHeight = 0;
    const balloonElement = document.getElementById(
      `balloon-${this.state.randomId}-group${this.props.group}`
    );
    if (balloonElement) divHeight = balloonElement.style.height;

    let outOfTime = false;
    if (this.props.outOfTime) {
      process.env.NODE_ENV === 'development' &&
        console.log('WE HAVE AN OUT OF TIME');
      if (
        this.props.outOfTime.balloon.dollars === this.props.balloon.dollars &&
        this.props.outOfTime.group === this.props.group
      ) {
        process.env.NODE_ENV === 'development' &&
          console.log("AND THEY'RE EQUAL");
        if (!this.props.donated) {
          outOfTime = true;
          // this.props.setCookie('popStatus', false, 1);
        }
      }
    }

    return (
      <>
        {/* <button
          id={`balloon-${this.props.balloon.dollars}`}
          className='ui circular button balloon'
          style={looks}
          onClick={this.openModal}
          disabled={!popStatus}
        >
          {this.props.balloon.popped ? this.props.balloon.dollars : null}
        </button> */}
        <div>
          {this.props.balloon.popped ? (
            <div
              id={`balloon-${this.state.randomId}-group${this.props.group}`}
              className='ui circular button balloon'
              style={{
                ...looks,
                // width: '60px',
                // height: '60px',
                // borderRadius: '100%',
                // fontSize: '0.9em',
                textAlign: 'center',
                verticalAlign: 'middle',
                // marginLeft: `0 ${this.state.random * 10}px`,
                // margin: '0 1.2em',
                // lineHeight: '30px',
                cursor: 'default',
                fontSize: `${this.props.balloon.randomSize * 30 + 20}px`,
                padding: 0,
                // lineHeight: `${this.props.balloon.randomSize * 60 + 20}px`,
                width: `${this.props.balloon.randomSize * 75 + 20}px`,
                marginTop: `${
                  this.state.random *
                  (divHeight +
                    1.377 * (this.props.balloon.randomSize * 60 + 15))
                }px`
              }}
            >
              {this.props.balloon.dollars}
            </div>
          ) : (
            <img
              id={`balloon-${this.state.randomId}-group${this.props.group}`}
              className='my-balloon'
              src={`/${this.props.balloon.color}-balloon.png`}
              alt={this.props.balloon.color}
              style={{
                ...looks,
                margin: '0 1.2em',
                // width: '50px',
                // width: '100%',
                // height: '100%',
                width: `${this.props.balloon.randomSize * 75 + 20}px`,
                // height: `${this.props.balloon.random * 60 + 20}px`,
                cursor: popStatus ? 'pointer' : 'default',
                opacity: popStatus ? '1' : '0.2',
                marginTop: `${
                  this.state.random *
                  (divHeight +
                    1.377 * (this.props.balloon.randomSize * 60 + 20))
                }px`,
                transition: 'width 2s',
                maxWidth: `130px`
              }}
              onClick={popStatus ? this.openModal : null}
              onMouseEnter={e =>
                popStatus ? (e.target.style.width = `130px`) : null
              }
              onMouseLeave={e =>
                (e.target.style.width = `${
                  this.props.balloon.randomSize * 75 + 20
                }px`)
              }
              // onClick={popStatus ? this.popBalloon : null}
            />
          )}
        </div>
        {/* {!this.props.balloon.popped ? (
          <Icon
            path={mdiBalloon}
            id={`balloon-${this.props.balloon.dollars}`}
            onClick={this.openModal}
            disabled={!popStatus}
            size='30px'
            color={this.props.balloon.color}
          />
        ) : (
          <div style={looks} className='ui circular button balloon'>
            {this.props.balloon.dollars}
          </div>
        )} */}
        {/* {!this.props.balloon.popped ? (
          <button
            id={`baloon-${this.props.balloon.dollars}`}
            className='ui circular button balloon'
            style={looks}
            onClick={this.openModal}
            disabled={!popStatus}
          >{this.props.balloon.dollars}</button>
        ) : (
          // <canvas id='balloon-pop-canvas' style={{ backgroundColor: 'green' }}>
          <button className='ui circular button balloon' style={looks}>
            {this.props.balloon.dollars}
          </button>
          // </canvas>
        )} */}
        <audio
          id='audio'
          src='/zapsplat_cartoon_pop_mouth_011_46674.mp3'
        ></audio>
        {showPaypal ? (
          <Modal
            isOpen={this.state.showPaypal}
            // onAfterOpen={this.state.afterOpenModal}
            // onRequestClose={this.state.closeModal}
            onAfterOpen={this.afterOpenModal}
            onAfterClose={this.afterCloseModal}
            style={customStyles}
            contentLabel='Payment Modal'
          >
            <h2
              style={{
                textAlign: 'center',
                // width: '90%',
                color: 'red'
                // paddingBottom: '20px'
              }}
              id={`balloon-${this.state.randomId}-group${this.props.group}-timer`}
            >
              {process.env.NODE_ENV === 'production' ? '10:00' : '00:30'}
            </h2>
            <Paypal
              dollars={this.props.balloon.dollars}
              groupId={this.props.group}
              color={this.props.balloon.color}
              outOfTime={outOfTime}
            />
            {!this.props.areYouSure && !outOfTime ? (
              <button
                className='ui button secondary'
                onClick={this.closeModal}
                style={{
                  height: '50px',
                  paddingLeft: '10px',
                  paddingRight: '10px'
                }}
              >
                Close
              </button>
            ) : this.props.areYouSure && !outOfTime ? (
              <>
                <button
                  className='ui button red'
                  onClick={this.closeModal}
                  style={{
                    height: '50px',
                    paddingLeft: '10px',
                    paddingRight: '10px'
                  }}
                >
                  Are you sure?
                </button>
                <div style={{ minWidth: '120px' }}>
                  If you exit without donating, you won't be able to pop another
                  balloon for 1 hour.
                </div>
              </>
            ) : (
              <button
                className='ui button secondary'
                onClick={this.closeModalFinal}
                style={{
                  height: '50px',
                  paddingLeft: '10px',
                  paddingRight: '10px'
                }}
              >
                Close
              </button>
            )}
          </Modal>
        ) : null}
      </>
    );
  }
}

const mapState = state => {
  return {
    donated: state.balloonReducer.donated,
    popStatus: state.balloonReducer.popStatus,
    areYouSure: state.balloonReducer.areYouSure,
    outOfTime: state.balloonReducer.outOfTime
  };
};

export default compose(
  connect(mapState, {
    popBalloon,
    completeDonation,
    balloonPopInProcess,
    setCookie,
    getCookie,
    setAreYouSure,
    setOutOfTime
  }),
  firestoreConnect(['group1', 'group2', 'group3'])
)(Balloon);
