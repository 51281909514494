import React from 'react';
import ReactDOM from 'react-dom';
import scriptLoader from 'react-async-script-loader';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { donate, setAreYouSure } from '../../actions';

// import Spinner from './Spinner';

// const CLIENT_ID = process.env.REACT_APP_PAYPAL_SANDBOX_KEY;
const CLIENT = {
  sandbox: process.env.REACT_APP_PAYPAL_SANDBOX_KEY,
  production: process.env.REACT_APP_PAYPAL_PRODUCTION_KEY
};

const ENV = process.env.NODE_ENV === 'production' ? 'production' : 'sandbox';
//create button here
let PaypalButton = null;

// next create the class and Bind React and ReactDom to window
//as we will be needing them later

class Paypal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showButtons: false,
      loading: true,
      paid: false
    };

    window.React = React;
    window.ReactDOM = ReactDOM;
  }

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;

    if (isScriptLoaded && isScriptLoadSucceed) {
      PaypalButton = window.paypal.Buttons.driver('react', { React, ReactDOM });
      this.setState({ loading: false, showButtons: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isScriptLoaded, isScriptLoadSucceed } = nextProps;

    const scriptJustLoaded =
      !this.state.showButtons && !this.props.isScriptLoaded && isScriptLoaded;

    if (scriptJustLoaded) {
      if (isScriptLoadSucceed) {
        PaypalButton = window.paypal.Buttons.driver('react', {
          React,
          ReactDOM
        });
        this.setState({ loading: false, showButtons: true });
      }
    }
  }

  createOrder = async (data, actions) => {
    await this.props.setAreYouSure(false);
    // {Math.ceil((0.029 * this.props.dollars + 0.31) * 100) / 100}
    const value =
      Math.ceil(
        (this.props.dollars +
          0.3 +
          0.029 * (this.props.dollars + 0.3 + this.props.dollars * 0.029)) *
          100
      ) / 100;
    // console.log(value);
    return actions.order.create({
      purchase_units: [
        {
          description: `Group ${this.props.groupId}: $${this.props.dollars} Balloon`,
          amount: {
            currency_code: 'USD',
            value
          }
        }
      ],
      application_context: {
        shipping_preference: 'NO_SHIPPING'
      }
    });
  };

  onApprove = async (data, actions) => {
    await this.props.donate();
    // await this.props.removePoppingBalloon();
    // this.setState({ showButtons: false, paid: true });
    actions.order.capture().then(details => {
      const paymentData = {
        payerID: data.payerID,
        orderID: data.orderID
      };
      // process.env.NODE_ENV === 'development' &&
      //   console.log('Payment Approved: ', paymentData);
      this.setState({ showButtons: false, paid: true });
      // await this.props.donate();
    });
  };

  reset = () => {
    this.props.setAreYouSure(false);
    this.setState({ showButtons: false });
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ showButtons: true, loading: false });
    }, 500);
  };

  render() {
    process.env.NODE_ENV === 'development' && console.log('RERENDERED');
    const { showButtons, loading, paid } = this.state;
    return (
      <div className='main'>
        {/* {loading && <Spinner />} */}

        {loading && <div style={{ textAlign: 'center' }}>LOADING</div>}

        {!this.props.outOfTime && showButtons && (
          <div>
            <div style={{ width: '100%' }}>
              <h1>
                You've popped the{' '}
                <span style={{ color: this.props.color, fontWeight: 'bold' }}>
                  ${this.props.dollars}
                </span>{' '}
                balloon 🎈 !
              </h1>
              <h2>Please choose a donation method.</h2>
              <h4>
                *Please note that the additional $
                {Math.ceil(
                  (0.3 +
                    0.029 *
                      (this.props.dollars + 0.3 + this.props.dollars * 0.029)) *
                    100
                ) / 100}{' '}
                is to cover processing fees.
              </h4>
            </div>
            {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ width: '80%' }}> */}
            <PaypalButton
              createOrder={(data, actions) => this.createOrder(data, actions)}
              onApprove={(data, actions) => this.onApprove(data, actions)}
              client={CLIENT}
              env={ENV}
            />
            {/* </div>
            </div> */}
            <button
              className='ui button primary'
              onClick={this.reset}
              style={{
                position: 'absolute',
                right: '16px',
                height: '50px',
                paddingLeft: '10px',
                paddingRight: '10px'
              }}
            >
              Reset
            </button>
          </div>
        )}

        {paid && (
          <div className='main'>
            {/* <img alt="Mercedes G-Wagon" src={Car} /> */}
            <h2 style={{ marginBottom: '10px' }}>
              You've just donated ${this.props.dollars}. May Allah accept from
              you!{' '}
              <span role='img' aria-label='emoji'>
                {' '}
                😄
              </span>
            </h2>
          </div>
        )}

        {this.props.outOfTime && !paid && (
          <div className='main'>
            <h2 style={{ marginBottom: '10px' }}>You're out of time :(</h2>
          </div>
        )}
      </div>
    );
  }
}

// export default scriptLoader(
//   `https://www.paypal.com/sdk/js?client-id=${CLIENT_ID}`
// )(Paypal);

export default compose(
  scriptLoader(
    `https://www.paypal.com/sdk/js?client-id=${
      ENV === 'production' ? CLIENT.production : CLIENT.sandbox
    }&components=buttons`
  ),
  connect(null, { donate, setAreYouSure })
)(Paypal);
