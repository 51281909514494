import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import _ from 'lodash';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
// import { useFirebase } from 'react-redux-firebase';

import './styles/team.css';

import {
  // fetchBalloons,
  // popBalloon,
  createBalloons
  // setListener
} from '../actions';
import Balloon from './Balloon';

class BalloonGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeMounted: Date.now(),
      balloonsPage: true
    };
  }
  async componentDidMount() {
    process.env.NODE_ENV === 'development' &&
      console.log('COMPONENT DID MOUNT');
    // await this.props.createBalloons(1);
    // await this.props.createBalloons(2);
    // await this.props.createBalloons(3);
    // await this.props.fetchBalloons(1);
    // await this.props.fetchBalloons(2);
    // await this.props.fetchBalloons(3);
    // await this.props.setListener(1);
    // await this.props.setListener(2);
    // await this.props.setListener(3);
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.team.id !== this.props.team.id) {
      process.env.NODE_ENV === 'development' &&
        console.log('COMPONENT DID UPDATE, TEAM CHANGED');
      // await this.props.fetchBalloons(1);
      // await this.props.fetchBalloons(2);
      // await this.props.fetchBalloons(3);
      // await this.props.setListener(1);
      // await this.props.setListener(2);
      // await this.props.setListener(3);
    }
  }

  createBalloons = async group => {
    await this.props.createBalloons(parseInt(group));
  };

  balloonPopAnimation = async (group, balloon, random) => {
    // await this.props.balloonPopAnimation(balloon);
    process.env.NODE_ENV === 'development' &&
      console.log('CALLING BALLOON POP ANIMATION');
    process.env.NODE_ENV === 'development' && console.log(random);
    this.props.balloonPopAnimation(group, balloon, random);
    process.env.NODE_ENV === 'development' && console.log('COMPLETE');
  };

  renderBalloons = group => {
    // const grid = ['1 / 1 / 2 / 2', '2 / 1 / 2 / 1', '1 / 3 / 4 / 4'];
    let count = 0;
    let balloons = [];
    const sortedBalloons = [...this.props[`group${group}`]];
    sortedBalloons.sort((x, y) =>
      x.randomPosition === y.randomPosition
        ? 0
        : x.randomPosition > y.randomPosition
        ? 1
        : -1
    );
    sortedBalloons.forEach(balloon => {
      balloons.push(
        <Balloon
          balloonPopAnimation={this.balloonPopAnimation}
          group={group}
          balloon={balloon}
          style={{
            // gridArea: grid[count],
            backgroundColor: 'green'
          }}
        />
      );
      count++;
      if (count > 2) count = 0;
    });
    return balloons;
  };

  renderFeed = () => {
    let feed = [];
    for (let i = this.props.feed.length - 1; i >= 0; i--) {
      let b = this.props.feed[i];
      feed.push(
        <div className='item' style={{ color: 'aliceblue' }}>
          <span style={{ color: b.balloon.color }}>
            {b.balloon.poppedBy.name}
          </span>{' '}
          from team{' '}
          <span style={{ color: b.balloon.color }}>
            {b.balloon.poppedBy.userTeam.toLowerCase()}
          </span>{' '}
          just popped balloon{' '}
          <span style={{ color: b.balloon.color }}>{b.balloon.dollars}</span>{' '}
          from group <span style={{ color: b.balloon.color }}>{b.group}</span>!
        </div>
      );
    }
    return feed;
  };

  render() {
    process.env.NODE_ENV === 'development' &&
      console.log('FEEEEEEEEEEEEEEEEEEEEED');
    process.env.NODE_ENV === 'development' && console.log(this.props.feed);
    // let sisters = this.props.sisters;
    // let brothers = this.props.brothers;
    // let brothers = this.props.team ? this.props.team.data.brothers : 0;
    // let sisters = this.props.team ? this.props.team.data.sisters : 0;

    let group1Raised = 0;
    let group1Count = 0;
    if (this.props.group1)
      this.props.group1.forEach(balloon => {
        if (balloon.popped) {
          group1Raised += balloon.dollars;
          group1Count++;
        }
      });
    let group2Raised = 0;
    let group2Count = 0;
    if (this.props.group2)
      this.props.group2.forEach(balloon => {
        if (balloon.popped) {
          group2Raised += balloon.dollars;
          group2Count++;
        }
      });
    let group3Raised = 0;
    let group3Count = 0;
    if (this.props.group3)
      this.props.group3.forEach(balloon => {
        if (balloon.popped) {
          group3Raised += balloon.dollars;
          group3Count++;
        }
      });
    // process.env.NODE_ENV === 'development' && console.log(sisters);
    // process.env.NODE_ENV === 'development' && console.log(brothers);
    return (
      <>
        {/* <div className='ui bottom attached segment pushable'>
          <div
            className='ui visible inverted left vertical sidebar menu'
            style={{ width: '18%' }}
          >
            <h3 style={{ color: 'aliceblue', textAlign: 'center' }}>
              {this.props.team.data.name}
            </h3>
            <a
              className='item'
              href='https://www.google.com'
              target='_blank'
              rel='noopener noreferrer'
            >
              <i className='home icon'></i>
              Home
            </a>
          </div>
          <div className='pusher'>
            <div className='ui basic segment'>
              <h1>
                HELLOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOJBEJIVBO -
                EIJOIJ PE JP9 EJPQWJDOIVNOIEVIOWEJ IJ PJWE OIEJ OIWEJ OIWEJ OWEJ
                OJ OWE OHO HOUH OURH OUFH OUHOUW HUOH OH H O
              </h1>
            </div> */}

        {/* </div> */}
        {/* </div> */}
        <div
          style={{
            display: 'flex',
            width: '100%'
          }}
        >
          <div style={{ width: '15%', minWidth: '220px' }}>
            <div
              className='ui secondary vertical menu'
              style={{
                width: '15%',
                minWidth: '220px',
                height: '100%',
                position: 'fixed',
                color: 'aliceblue',
                backgroundColor: '#1b1c1d',
                overflowY: 'auto',
                border: 'none'
              }}
            >
              <h3
                className='item'
                style={{
                  textAlign: 'center',
                  paddingTop: '10px',
                  color: 'aliceblue',
                  lineHeight: '40px'
                }}
              >
                <div
                  style={{ display: 'flex', justifyContent: 'space-around' }}
                >
                  {this.props.team.data.name}
                  <img
                    src='/cw-logo.png'
                    alt='cw-logo'
                    style={{ height: 40 }}
                  />
                </div>
              </h3>
              <a
                className='item'
                href='https://www.bcisomwei.org'
                target='_blank'
                rel='noopener noreferrer'
                style={{ color: 'aliceblue', marginTop: '-5px' }}
              >
                <i className='home icon' style={{ color: 'aliceblue' }}></i>
                bcisomwei
              </a>
              {this.props.team ? (
                <>
                  <div
                    className='item'
                    style={{
                      color: 'aliceblue',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <span>
                      Group 1:{' '}
                      <span
                        style={{
                          color: 'rgb(214, 60, 86)',
                          paddingLeft: '5px',
                          paddingRight: '5px'
                        }}
                      >
                        {this.props.team.data.group1Rounds}
                      </span>
                    </span>
                    <span
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        color: 'rgb(214, 60, 86)',
                        alignItems: 'center'
                      }}
                    >
                      <span>{group1Count}</span>
                      <span style={{ borderTop: '1px solid rgb(214, 60, 86)' }}>
                        10
                      </span>
                    </span>
                    <span style={{ paddingLeft: '5px' }}> rounds</span>
                  </div>
                  <div
                    className='item'
                    style={{
                      color: 'aliceblue',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <span>
                      Group 2:{' '}
                      <span
                        style={{
                          color: 'rgb(214, 60, 86)',
                          paddingLeft: '5px',
                          paddingRight: '5px'
                        }}
                      >
                        {this.props.team.data.group2Rounds}
                      </span>
                    </span>
                    <span
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        color: 'rgb(214, 60, 86)',
                        alignItems: 'center'
                      }}
                    >
                      <span>{group2Count}</span>
                      <span style={{ borderTop: '1px solid rgb(214, 60, 86)' }}>
                        35
                      </span>
                    </span>
                    <span style={{ paddingLeft: '5px' }}> rounds</span>
                  </div>
                  <div
                    className='item'
                    style={{
                      color: 'aliceblue',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <span>
                      Group 3:{' '}
                      <span
                        style={{
                          color: 'rgb(214, 60, 86)',
                          paddingLeft: '5px',
                          paddingRight: '5px'
                        }}
                      >
                        {this.props.team.data.group3Rounds}
                      </span>
                    </span>
                    <span
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        color: 'rgb(214, 60, 86)',
                        alignItems: 'center'
                      }}
                    >
                      <span>{group3Count}</span>
                      <span style={{ borderTop: '1px solid rgb(214, 60, 86)' }}>
                        50
                      </span>
                    </span>
                    <span style={{ paddingLeft: '5px' }}> rounds</span>
                  </div>
                </>
              ) : null}
              <div
                className='item'
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  width: '100%'
                }}
              >
                <div
                  className='ui button'
                  style={{
                    // width: '45%',
                    border: '2px solid #ff851b',
                    backgroundColor: 'transparent',
                    color: '#ff851b',
                    textAlign: 'center',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    minWidth: '90px'
                  }}
                  onMouseEnter={e => {
                    e.target.style.backgroundColor = '#ff851b';
                    e.target.style.color = '#1b1c1d';
                    e.target.innerHTML = 'Brothers';
                  }}
                  onMouseLeave={e => {
                    e.target.style.backgroundColor = 'transparent';
                    e.target.style.color = '#ff851b';
                    e.target.innerHTML = `$ ${
                      this.props.team.data ? this.props.team.data.brothers : 0
                    }`;
                  }}
                >
                  $ {this.props.team.data ? this.props.team.data.brothers : 0}
                </div>
                <div
                  className='ui button'
                  style={{
                    // width: '45%',
                    border: '2px solid #6dffff',
                    backgroundColor: 'transparent',
                    color: '#6dffff',
                    textAlign: 'center',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    minWidth: '90px'
                  }}
                  onMouseEnter={e => {
                    e.target.style.backgroundColor = '#6dffff';
                    e.target.style.color = '#1b1c1d';
                    e.target.innerHTML = 'Sisters';
                  }}
                  onMouseLeave={e => {
                    e.target.style.backgroundColor = 'transparent';
                    e.target.style.color = '#6dffff';
                    e.target.innerHTML = `$ ${
                      this.props.team.data ? this.props.team.data.sisters : 0
                    }`;
                  }}
                >
                  $ {this.props.team.data ? this.props.team.data.sisters : 0}
                </div>
              </div>
              <div
                id='feed'
                style={{
                  overflowY: 'auto',
                  // height: 'calc(100% - 275px)',
                  maxHeight: '50%',
                  borderTop: '5px solid aliceblue'
                  // borderTopWidth: '5px',
                  // '-webkit-box-shadow':
                  //   '0px 10px 5px rgba(255, 255, 255, 0.75) inset, 0px -10px 5px rgba(255, 255, 255, 0.75) inset',
                  // '-moz-box-shadow':
                  //   '0px 10px 5px rgba(255, 255, 255, 0.75) inset, 0px -10px 5px rgba(255, 255, 255, 0.75) inset',
                  // 'box-shadow':
                  //   '0px 10px 5px rgba(255, 255, 255, 0.75) inset, 0px -10px 5px rgba(255, 255, 255, 0.75) inset'
                }}
              >
                {this.props.feed ? (
                  <div style={{ marginTop: '5px' }}>{this.renderFeed()}</div>
                ) : (
                  <div
                    className='item'
                    style={{
                      color: 'rgba(255, 255, 255, 0.5)',
                      textAlign: 'center',
                      marginTop: '5px'
                    }}
                  >
                    Latest pops will show up here
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              width: '85%',
              backgroundColor: 'aliceblue',
              overflowX: 'auto'
            }}
          >
            <div className='ui basic segment' style={{ width: '100%' }}>
              <div
                style={{
                  color: 'rgb(214, 60, 86)',
                  textAlign: 'center',
                  fontSize: '3rem',
                  fontWeight: 'bold',
                  marginTop: '2%',
                  lineHeight: '40px',
                  paddingBottom: 0
                }}
              >
                CHARITY POP
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  maxWidth: '200px',
                  minWidth: '150px'
                  // textAlign: 'center'
                }}
              >
                <div
                  onClick={() => this.setState({ balloonsPage: true })}
                  style={{
                    // textAlign: 'center',
                    cursor: 'default',
                    // width: '100%',
                    disabled: this.state.balloonsPage,
                    opacity: this.state.balloonsPage ? 1 : 0.5,
                    fontWeight: 'bold',
                    color: '#1b1c1d'
                  }}
                >
                  Balloons
                </div>
                <div
                  onClick={() => this.setState({ balloonsPage: false })}
                  style={{
                    // textAlign: 'center',
                    cursor: 'default',
                    // width: '100%',
                    disabled: !this.state.balloonsPage,
                    opacity: !this.state.balloonsPage ? 1 : 0.5,
                    fontWeight: 'bold',
                    color: '#1b1c1d'
                  }}
                >
                  Causes
                </div>
              </div>
            </div>
            {this.state.balloonsPage ? (
              <div>
                <div
                  className='ui basic segment'
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%'
                  }}
                >
                  {/* <h2>{this.props.team.data.name}</h2> */}
                  {/* <button
                className='ui secondary button'
                value='1'
                onClick={event => this.createBalloons(event.target.value)}
              >
                Create Balloons
              </button> */}
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                      fontWeight: 'bold',
                      // marginTop: '5%',
                      marginBottom: '2%',
                      color: 'aliceblue',
                      fontSize: '1.2rem'
                    }}
                    className='ui inverted segment'
                  >
                    <div style={{ width: '100px' }}>Group 1</div>
                    <div style={{ width: '100px' }}>$6 to $15</div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '120px',
                        justifyContent: 'space-between'
                      }}
                    >
                      <div>$</div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                      >
                        <div>{group1Raised}</div>
                        <div style={{ borderTop: '2px solid aliceblue' }}>
                          105
                        </div>
                      </div>
                      <div>raised</div>
                    </div>
                  </div>
                  {/* {this.props.group1 ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap'
                  }}
                >
                  {this.props.group1.balloons ? this.renderBalloons(1) : null}
                </div>
              ) : null} */}
                  {this.props.group1 ? (
                    <div
                      style={{
                        display: 'flex',
                        // gridTemplateColumns: '60px 30px 30px 90px',
                        // gridTemplateRows: '60px 30px 30px 90px',
                        justifyContent: 'center',
                        // width: '300px',
                        flexWrap: 'wrap'
                      }}
                    >
                      {this.props.group1 ? this.renderBalloons(1) : null}
                    </div>
                  ) : null}
                  {/* <button
                className='ui secondary button'
                value='2'
                onClick={event => this.createBalloons(event.target.value)}
              >
                Create Balloons
              </button> */}
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                      fontWeight: 'bold',
                      marginTop: '5%',
                      marginBottom: '2%',
                      color: 'aliceblue',
                      fontSize: '1.2rem'
                    }}
                    className='ui inverted segment'
                  >
                    <div style={{ width: '100px' }}>Group 2</div>
                    <div style={{ width: '100px' }}>$16 to $50</div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '120px',
                        justifyContent: 'space-between'
                      }}
                    >
                      <div>$</div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                      >
                        <div>{group2Raised}</div>
                        <div style={{ borderTop: '2px solid aliceblue' }}>
                          1155
                        </div>
                      </div>
                      <div>raised</div>
                    </div>
                  </div>
                  {this.props.group2 ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap'
                        // width: '600px'
                      }}
                    >
                      {this.props.group2 ? this.renderBalloons(2) : null}
                    </div>
                  ) : null}
                  {/* <button
                className='ui secondary button'
                value='3'
                onClick={event => this.createBalloons(event.target.value)}
              >
                Create Balloons
              </button> */}
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                      fontWeight: 'bold',
                      marginTop: '5%',
                      marginBottom: '2%',
                      color: 'aliceblue',
                      fontSize: '1.2rem'
                    }}
                    className='ui inverted segment'
                  >
                    <div style={{ width: '100px' }}>Group 3</div>
                    <div style={{ width: '100px' }}>$51 to $100</div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '120px',
                        justifyContent: 'space-between'
                      }}
                    >
                      <div>$</div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                      >
                        <div>{group3Raised}</div>
                        <div style={{ borderTop: '2px solid aliceblue' }}>
                          3775
                        </div>
                      </div>
                      <div>raised</div>
                    </div>
                  </div>
                  {this.props.group3 ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap'
                      }}
                    >
                      {this.props.group3 ? this.renderBalloons(3) : null}
                    </div>
                  ) : null}
                </div>
              </div>
            ) : (
              <div
                style={{
                  // overflow: 'auto',
                  // height: '100%',
                  backgroundColor: 'aliceblue',
                  marginLeft: '-30px'
                }}
              >
                <div
                  style={{
                    overflowX: 'auto',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <img
                    src='./cw-2021-projects.jpeg'
                    alt='cw-2021-projects'
                    style={{
                      padding: '20px 10px'
                    }}
                  />
                  {/* <img
                    src='./cause_surgery.jpg'
                    alt='cause_surgery'
                    style={{
                      width: '500px',
                      height: '500px',
                      padding: '20px 10px'
                    }}
                  />
                  <img
                    src='./cause_medical.png'
                    alt='cause_medical'
                    style={{
                      width: '500px',
                      height: '500px',
                      padding: '20px 10px'
                    }}
                  />
                  <img
                    src='./cause_emergency.jpg'
                    alt='cause_emergency'
                    style={{
                      width: '500px',
                      height: '500px',
                      padding: '20px 10px'
                    }}
                  />
                  <img
                    src='./cause_education.jpg'
                    alt='cause_education'
                    style={{ width: '500px', height: '500px', padding: '10px' }}
                  /> */}
                </div>
              </div>
            )}
            <div
              className='my-footer'
              style={{ marginTop: '50px', marginBottom: '50px' }}
            >
              <div style={{ fontWeight: 'bold', color: '#1b1c1d' }}>
                By{' '}
                <a
                  href='https://www.youtube.com/channel/UC6AJoGoxMIO1dBQ1kyoWEOw'
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{
                    textDecoration: 'none !important',
                    color: 'rgb(214, 60, 86)'
                  }}
                >
                  Manahil Sher
                </a>
              </div>
              {/* <div style={{ textAlign: 'end' }}>
                Balloon popping sound from Zapsplat.com
              </div> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapState = state => {
  process.env.NODE_ENV === 'development' && console.log('WUIHRIESBRKFKJHWE');
  process.env.NODE_ENV === 'development' &&
    console.log(state.balloonReducer.selectedTeam);
  return {
    group1: state.balloonReducer.group1,
    group2: state.balloonReducer.group2,
    group3: state.balloonReducer.group3,
    feed: state.balloonReducer.feed,
    team: state.balloonReducer.selectedTeam
  };
};

export default compose(
  connect(mapState, { createBalloons }),
  firestoreConnect(['group1', 'group2', 'group3'])
)(BalloonGroup);
