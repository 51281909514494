import { combineReducers } from 'redux';
// import teamReducer from './teamReducer';
import balloonReducer from './balloonReducer';
// import donationReducer from './donationReducer';
// import { firestoreReducer } from 'redux-firestore';
// import { firebaseReducer } from 'react-redux-firebase';

export default combineReducers({
  //   firestoreReducer: firestoreReducer,
  //   firebase: firebaseReducer,
  // teamReducer: teamReducer,
  balloonReducer: balloonReducer
  // donationReducer: donationReducer
});
