export const CREATE_BALLOONS = 'CREATE_BALLOONS';
export const FETCH_BALLOON = 'FETCH_BALLOON';
export const FETCH_BALLOONS = 'FETCH_BALLOONS';
export const UPDATE_BALLOON = 'UPDATE_BALLOON';
export const OPEN_BALLOON = 'OPEN_BALLOON';
export const REMOVE_POPPING_BALLOON = 'REMOVE_POPPING_BALLOON';
export const ADD_TO_FEED = 'ADD_TO_FEED';
export const SET_OUT_OF_TIME = 'SET_OUT_OF_TIME';
export const SET_TIMER = 'SET_TIMER';

export const DONATE = 'DONATE';
export const COMPLETE_DONATION = 'COMPLETE_DONATION';
export const SET_ARE_YOU_SURE = 'SET_ARE_YOU_SURE';
export const SAVE_INFO = 'SAVE_INFO';

export const SELECT_TEAM = 'SELECT_TEAM';
export const FETCH_TEAMS = 'FETCH_TEAMS';
export const UPDATE_TEAM = 'UPDATE_TEAM';

export const SET_POP_STATUS = 'SET_POP_STATUS';
