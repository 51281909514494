import React from 'react';
import './App.css';
import BalloonGroup from '../BalloonGroup';
import Header from '../Header';
// import $ from 'jquery';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import Modal from 'react-modal';
import $ from 'jquery';
import {
  selectTeam,
  // fetchTeams,
  getCookie,
  setCookie,
  setPopStatus,
  undoDBPop,
  balloonPopInProcess,
  saveInfo,
  setTeamListener
} from '../../actions';
import { reduce } from 'lodash';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    // maxHeight: 'calc(100vh - 210px)',
    overflow: 'auto',
    width: '35%',
    // height: '30%',
    minWidth: '240px',
    height: '30%',
    minHeight: '200px',
    maxHeight: '70%',
    backgroundColor: '#1b1c1d'

    //  top: '50%',
    // left: '50%',
    // right: 'auto',
    // bottom: 'auto',
    // marginRight: '-50%',
    // transform: 'translate(-50%, -50%)',
    // // maxHeight: 'calc(100vh - 210px)',
    // overflow: 'auto',
    // width: '35%',
    // minWidth: '200px',
    // maxHeight: '70%'
  }
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPaypal: false,
      pageX: null,
      pageY: null,
      showWelcome: true,
      name: 'Anonymous',
      userTeam: null,
      feed: [],
      startedTimer: false
    };
  }

  async componentDidMount() {
    process.env.NODE_ENV === 'development' &&
      console.log('COMPONENT DID MOUNT');
    if (this.props.getCookie('popStatus') === null) {
      process.env.NODE_ENV === 'development' &&
        console.log(
          'THERE IS NO POP STATUS COOKIE, SETTING POP STATUS TO TRUE'
        );
      await this.props.setCookie('popStatus', true, 1);
    } else {
      process.env.NODE_ENV === 'development' &&
        console.log('THERE IS A POP STATUS COOKIE');
      if (this.props.getCookie('popStatus') === 'true') {
        process.env.NODE_ENV === 'development' &&
          console.log('POP STATUS COOKIE IS TRUE, SETTING IT TO TRUE IN STORE');
        this.props.setPopStatus(true);
      } else {
        process.env.NODE_ENV === 'development' &&
          console.log(
            'POP STATUS COOKIE IS FALSE, SETTING IT TO FALSE IN STORE'
          );
        // if the user somehow refreshed or the like before the timeout finished in order to reset popStatus to true
        // then there's no handling to set popStatus to true after the appropriate time
        // so here we set popStatus to true when the time designated in the canPopAt cookie is reached
        const startTime = this.props.getCookie('canPopAt');
        process.env.NODE_ENV === 'development' && console.log(startTime);
        const canPopTime = document.getElementById('can-pop-time');
        if (startTime) {
          const endTime = new Date();
          const timePassed = endTime - startTime;
          process.env.NODE_ENV === 'development' &&
            console.log(startTime, endTime);
          process.env.NODE_ENV === 'development' && console.log(timePassed);
          if (
            timePassed >=
            (process.env.NODE_ENV === 'production' ? 3600000 : 60000)
          ) {
            process.env.NODE_ENV === 'development' &&
              console.log('setting popStatus to true right away');
            this.props.setCookie('popStatus', true, 1);
          } else {
            this.props.setPopStatus(false);
            canPopTime.style.zIndex = 1000;
            canPopTime.style.visibility = 'visible';
            let duration =
              process.env.NODE_ENV === 'production'
                ? (3600000 - timePassed) / 1000
                : (60000 - timePassed) / 1000;
            // 60 * minutes
            // 1 minute = 1
            // duration should equal 60 if timepassed is 0
            // duration should equal 50 if timepassed is 10000
            process.env.NODE_ENV === 'development' && console.log(duration);
            let timer = duration;
            let minutes;
            let seconds;
            const canPopInterval = setInterval(() => {
              minutes = parseInt(timer / 60, 10);
              seconds = parseInt(timer % 60, 10);

              minutes = minutes < 10 ? '0' + minutes : minutes;
              seconds = seconds < 10 ? '0' + seconds : seconds;
              process.env.NODE_ENV === 'development' &&
                console.log(minutes, seconds);
              canPopTime.innerHTML = `${minutes}:${seconds}`;
              // console.log(minutes, seconds);
              if (--timer < 0) {
                // timer = duration;
                this.props.setCookie('popStatus', true, 1);
                canPopTime.innerHTML = '';
                canPopTime.style.zIndex = -2;
                canPopTime.style.visibility = 'hidden';
                clearInterval(canPopInterval);
              }
            }, 1000);
            // canPopInterval();
            // setTimeout(() => {
            //   process.env.NODE_ENV === 'development' &&
            //     console.log('setting popStatus to true');
            //   this.props.setCookie('popStatus', true, 1);
            // }, 3600000 - timePassed);
          }
        } else {
          process.env.NODE_ENV === 'development' &&
            console.log('NO START TIME');
          // this.props.setCookie('popStatus', false, 1);
        }
      }
    }
    window.addEventListener('beforeunload', async e => {
      process.env.NODE_ENV === 'development' && console.log('CHECCKING');
      if (this.props.user) {
        process.env.NODE_ENV === 'development' &&
          console.log('INSIDE BEFORE UNLOAAAAD');
        // Cancel the event
        e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        process.env.NODE_ENV === 'development' &&
          console.log('INSIDE BEFORE UNLOAAAAD');
        if (this.props.donated) {
          process.env.NODE_ENV === 'development' &&
            console.log('USER REFRESHING AFTER DONATION BEFORE CLOSE MODAL');
          // this.props.popBalloon(
          //   this.props.maybePoppedBalloon.group,
          //   this.props.maybePoppedBalloon.balloon
          // );
          this.props.setCookie('popStatus', true, 1);
          this.props.balloonPopInProcess(
            this.props.maybePoppedBalloon.group,
            this.props.maybePoppedBalloon.balloon,
            false,
            true,
            true,
            true
          );
        } else if (this.props.poppingBalloon) {
          process.env.NODE_ENV === 'development' &&
            console.log(
              '%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%'
            );
          process.env.NODE_ENV === 'development' &&
            console.log('POPPING BALLOOON EXISTSS!!!');
          process.env.NODE_ENV === 'development' &&
            console.log(
              "SETTING POP STATUS COOKIE TO FALSE FOR ONE MINUTE CUZ THERE'S A POP BALLOON"
            );
          this.props.setCookie('popStatus', false, 1);
          await this.props.undoDBPop();
        } else {
          // process.env.NODE_ENV === "development" && console.log( 'THERE IS NO POPPING BALLOON AND DONATED IS FALSE?');
          // this.props.setCookie('popStatus', true, 1);
        }
        // if (this.props.getCookie('canPopAt')) process.env.NODE_ENV === "development" && console.log( 'HELLOOOOOOO');
        // Chrome requires returnValue to be set
        // e.returnValue = '';
        // the absence of a returnValue property on the event will guarantee the browser unload happens
        // delete e['returnValue'];
      } else {
        process.env.NODE_ENV === 'development' &&
          console.log('NOT GONNA DO ANYTHING');
      }
    });
    // await this.props.fetchTeams();
    await this.props.selectTeam(1);
    await this.props.setTeamListener();
  }

  componentDidUpdate() {
    process.env.NODE_ENV === 'development' &&
      console.log('component did update app.js');
  }

  showPaypalButtons = () => {
    this.setState({ showPaypal: true });
  };

  selectTeam = async teamId => {
    await this.props.selectTeam(teamId);
  };

  balloonPopAnimation = (group, balloon, random) => {
    // console.log(random);
    // process.env.NODE_ENV === "development" && console.log( group);
    // process.env.NODE_ENV === "development" && console.log( balloon);
    // process.env.NODE_ENV === "development" && console.log( 'BALLOON POP ANIMATION');
    // Shim with setTimeout fallback
    // document.createElement('canvas');
    // const canvas = document.getElementsByTagName('canvas')[0];

    const canvas = $('#balloon-pop-canvas');
    // canvas.style.zIndex = 10000;
    canvas.css('z-index', 10000);

    // process.env.NODE_ENV === "development" && console.log( 'BALLOON POP ANIMATION');
    const ctx = canvas[0].getContext('2d');
    // process.env.NODE_ENV === "development" && console.log( 'BALLOON POP ANIMATION');

    const balloonElement = $(`#balloon-${random}-group${group}`);
    // console.log(document.getElementById(`balloon-${random}-group${group}`));
    // console.log(balloonElement);
    // const balloonRect = balloonElement.css(getBoundingClientRect());

    // var src = canvas;
    // var dest = balloonElement;
    // var holder = $('.entire-container');
    // holder.width(dest.width());
    // holder.height(dest.height());
    // Setup
    // src.hide();
    // Interaction
    // dest.click(function () {
    // src.show();
    // src.css('position', 'absolute');
    // src.width(dest.width());
    // src.height(dest.height());
    // canvas.css('top', balloonElement.offset().top);
    // canvas.css('left', balloonElement.offset().left);
    // canvas.offset(balloonElement.offset());
    // process.env.NODE_ENV === "development" && console.log( canvas.offset());
    // dest.hide();
    // });
    // src.click(function () {
    //   dest.show();
    //   src.hide();
    // });

    // const { top, left } = balloonElement.offset();
    // process.env.NODE_ENV === "development" && console.log( balloonRect);

    // let laX = balloonRect.left + window.scrollX;
    // let laY = balloonRect.top + window.scrollY;
    // process.env.NODE_ENV === "development" && console.log( balloonRect.left, window.scrollX);
    // process.env.NODE_ENV === "development" && console.log( balloonRect.top, window.scrollY);
    // let laX = window.scrollX;
    // let laY = window.scrollY;
    // let laX = balloonRect.left;
    // let laY = balloonRect.top;
    // const rect = document
    //   .getElementById(`balloon-${balloon.dollars}-group${group}`)
    //   .getBoundingClientRect();
    // let laX = rect.x;
    // let laY = rect.y;
    // var viewportOffset = document
    //   .getElementById('balloon-pop-canvas')
    //   .getBoundingClientRect();
    // these are relative to the viewport, i.e. the window
    // let laY = viewportOffset.top + 100;
    // let laX = viewportOffset.left - 250;
    // let laX = canvas.css('left');
    // let laY = canvas.css('top');
    // process.env.NODE_ENV === "development" && console.log( left, window.scrollX);
    // process.env.NODE_ENV === "development" && console.log( top, window.scrollY);
    // let W = (canvas.width = Math.max(
    //   document.body.scrollHeight,
    //   document.body.offsetHeight,
    //   window.clientHeight,
    //   window.scrollHeight,
    //   window.offsetHeight
    // ));
    // let H = (canvas.height = Math.max(
    //   document.body.scrollHeight,
    //   document.body.offsetHeight,
    //   window.clientHeight,
    //   window.scrollHeight,
    //   window.offsetHeight
    // ));
    // let W = (canvas.width = $(
    //   'entire-container'
    // ).clientWidth);
    // let H = (canvas.height = $(
    //   'entire-container'
    // ).clientHeight);
    // let H = (canvas.height = window.height);
    // let W = (canvas.width = window.width);
    // let H = (canvas.height = 500);
    // let W = (canvas.width = 500);
    // var viewportOffset = el.getBoundingClientRect();
    // // these are relative to the viewport, i.e. the window
    // var top = viewportOffset.top;
    // var left = viewportOffset.left;
    const balloonEl = document.getElementById(
      `balloon-${random}-group${group}`
    );
    // process.env.NODE_ENV === "development" && console.log( balloonEl.style.marginTop);
    // let marginTop = parseInt(
    //   balloonEl.style.marginTop.substring(
    //     0,
    //     balloonEl.style.marginTop.indexOf('p')
    //   )
    // );
    // let marginLeft = parseInt(
    //   balloonEl.style.marginLeft.substring(
    //     0,
    //     balloonEl.style.marginLeft.indexOf('p')
    //   )
    // );
    let balloonHeight = balloonElement.height() / 2;
    let balloonWidth = balloonElement.width() / 2;
    process.env.NODE_ENV === 'development' &&
      console.log(balloonHeight, balloonWidth);
    // let laY = balloonEl.getBoundingClientRect().y + marginTop + balloonHeight;
    let laY = balloonEl.getBoundingClientRect().y + balloonHeight;
    // let laX = balloonEl.getBoundingClientRect().x + marginLeft + balloonWidth;
    let laX = balloonEl.getBoundingClientRect().x + balloonWidth + 10;
    let W = (document.getElementById('balloon-pop-canvas').width =
      window.innerWidth);
    let H = (document.getElementById('balloon-pop-canvas').height =
      window.innerHeight);
    // canvas.style.top = `${laY}px`;
    // canvas.style.left = `${laX}px`;
    // canvas.css('transform', `translate(${left}px, ${top}px)`);
    // canvas.style.transform = `translate(${left}px, ${top}px)`;
    // Let's set our gravity
    let gravity = 0.9;

    process.env.NODE_ENV === 'development' && console.log(laX, laY, W, H);

    // canvas.style.left = laX / 2;
    // canvas.style.top = laY / 2;
    // canvas.style.visibility = 'visible';

    // Time to write a neat constructor for our
    // particles.
    // Lets initialize a random color to use for
    // our particles and also define the particle
    // count.
    // let particle_count = parseInt(Math.random() * 1000);
    let particle_count = 200;
    let particles = [];

    // let colors = ['#ffff35', '#fe5e31'];
    // let random_color = colors[Math.floor(Math.random() * colors.length)];
    let random_color = balloon.color;

    class Particle {
      constructor() {
        // process.env.NODE_ENV === "development" && console.log( 'CONSTRUCTOR');
        this.radius = parseInt(Math.random() * 10);
        this.x = laX;
        this.y = laY;

        process.env.NODE_ENV === 'development' && console.log(this.x, this.y);

        this.color = random_color;

        // Random Initial Velocities
        this.vx = Math.random() * 20 - 10;
        // vy should be negative initially
        // then only will it move upwards first
        // and then later come downwards when our
        // gravity is added to it.
        this.vy = Math.random() * -14 - 1;

        // Finally, the function to draw
        // our particle
        this.draw = function () {
          // process.env.NODE_ENV === "development" && console.log( 'DRAW IS RUNNING');
          ctx.fillStyle = this.color;

          ctx.beginPath();

          ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
          ctx.fill();

          ctx.closePath();
        };
      }
    }

    // Now lets quickly create our particle
    // objects and store them in particles array
    for (let i = 0; i < particle_count; i++) {
      // process.env.NODE_ENV === "development" && console.log( 'MAKING A PARTICLE?');
      let particle = new Particle();
      particles.push(particle);
    }
    // process.env.NODE_ENV === "development" && console.log( particles);

    // Finally, writing down the code to animate!
    let count1 = 0;
    (function renderFrame() {
      count1++;
      if (count1 > 50) {
        canvas.css('z-index', -1);
        // canvas.style.zIndex = 10000;
        // process.env.NODE_ENV === "development" && console.log( 'RRRREEETURNNNING');
        return;
      }
      requestAnimationFrame(renderFrame);
      // process.env.NODE_ENV === "development" && console.log( 'THIS THING');

      // Clearing screen to prevent trails
      ctx.clearRect(0, 0, W, H);

      // process.env.NODE_ENV === "development" && console.log( particles.length);
      // process.env.NODE_ENV === "development" && console.log( particle_count);
      let count = 0;
      // process.env.NODE_ENV === "development" && console.log( 'about to draw', count);

      particles.forEach(function (particle) {
        // process.env.NODE_ENV === "development" && console.log( 'for each');
        // The particles simply go upwards
        // It MUST come down, so lets apply gravity
        particle.vy += gravity;

        // Adding velocity to x and y axis
        particle.x += particle.vx;
        particle.y += particle.vy;

        // We're almost done! All we need to do now
        // is to reposition the particles as soon
        // as they move off the canvas.
        // We'll also need to re-set the velocities

        particle.draw();
        count++;
        // process.env.NODE_ENV === "development" && console.log( count);
        // if (count > 5000) break;
      });
      // canvas.style.zIndex = -1;
    })();
    // process.env.NODE_ENV === "development" && console.log( 'complete');
    // canvas.style.zIndex = -1;
    // canvas.css('z-index', -1);
  };

  saveInfo = async () => {
    let name = 'Anonymous';
    if (!this.state.userTeam) {
      alert('Please select your team');
      return;
    }
    if (this.state.name.length > 0) {
      name = this.state.name;
    }
    process.env.NODE_ENV === 'development' && console.log('SAVING INFO');
    await this.props.saveInfo({
      name,
      userTeam: this.state.userTeam
    });
    process.env.NODE_ENV === 'development' && console.log('saved user info');
    process.env.NODE_ENV === 'development' && console.log(this.props.user);
    this.setState({ showWelcome: false });
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  timerHandle = () => {
    process.env.NODE_ENV === 'development' && console.log('WE ARE IN HERE');
    this.setState({ startedTimer: true });
    const startTime = this.props.timerStart;
    const endTime = new Date();
    const timePassed = endTime - startTime;
    process.env.NODE_ENV === 'development' && console.log(startTime, endTime);
    process.env.NODE_ENV === 'development' && console.log(timePassed);
    if (
      timePassed < (process.env.NODE_ENV === 'production' ? 3600000 : 60000)
    ) {
      const canPopTime = document.getElementById('can-pop-time');
      canPopTime.style.zIndex = 1000;
      canPopTime.style.visibility = 'visible';
      let duration = process.env.NODE_ENV === 'production' ? 3600 : 60;
      process.env.NODE_ENV === 'development' && console.log(duration);
      let timer = duration;
      let minutes;
      let seconds;
      const cookieInterval = setInterval(() => {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;
        process.env.NODE_ENV === 'development' && console.log(minutes, seconds);
        canPopTime.innerHTML = `${minutes}:${seconds}`;
        if (--timer < 0) {
          canPopTime.innerHTML = '';
          canPopTime.style.zIndex = -2;
          canPopTime.style.visibility = 'hidden';
          this.setState({ startedTimer: false });
          clearInterval(cookieInterval);
        }
      }, 1000);
    }
  };

  render() {
    if (this.props.timerStart && !this.state.startedTimer) {
      this.timerHandle();
    }
    // if (this.props.group1 && this.props.group2 && this.props.group3) {
    //   let newGroup1 = this.props.group1.balloons
    //     .filter(
    //       balloon =>
    //         balloon.poppedBy &&
    //         !this.state.feed.find(
    //           b => b.dollars === balloon.dollars && b.group === 1
    //         )
    //     )
    //     .map(balloon => ({ group: 1, balloon }));
    //   let newGroup2 = this.props.group2.balloons
    //     .filter(
    //       balloon =>
    //         balloon.poppedBy &&
    //         !this.state.feed.find(
    //           b => b.dollars === balloon.dollars && b.group === 2
    //         )
    //     )
    //     .map(balloon => ({ group: 2, balloon }));
    //   let newGroup3 = this.props.group3.balloons
    //     .filter(
    //       balloon =>
    //         balloon.poppedBy &&
    //         !this.state.feed.find(
    //           b => b.dollars === balloon.dollars && b.group === 1
    //         )
    //     )
    //     .map(balloon => ({ group: 3, balloon }));
    //   let feed = [...this.state.feed, newGroup1, newGroup2, newGroup3];
    //   process.env.NODE_ENV === "development" && console.log( 'FEEEEED');
    //   process.env.NODE_ENV === "development" && console.log( feed);
    //   if (feed.length > this.state.feed.length) {
    //     process.env.NODE_ENV === "development" && console.log( 'found more');
    //     // this.setState({ feed });
    //   }
    // }
    // process.env.NODE_ENV === "development" && console.log( 'ABRARS TEST: ' + process.env.NODE_ENV);
    return (
      <>
        {/* <Header
          // teams={this.props.teams ? this.props.teams : null}
          // selectTeam={this.selectTeam}
          selectedTeam={
            this.props.selectedTeam ? this.props.selectedTeam : null
          }
        /> */}
        <div id='entire-container'>
          <Modal
            isOpen={this.state.showWelcome}
            contentLabel='Welcome Modal'
            style={customStyles}
          >
            <div
              className='ui segment'
              style={{
                height: '100%',
                justifyContent: 'space-between',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#1b1c1d',
                padding: 0
              }}
            >
              <input
                type='text'
                name='name'
                value={this.state.name}
                onChange={this.handleChange}
                style={{
                  width: '100%',
                  borderRadius: '4px',
                  height: '30%',
                  border: 'none',
                  outline: 'none',
                  fontSize: '20px',
                  padding: '10px'
                }}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  height: '30%'
                }}
              >
                <button
                  className='ui inverted button orange'
                  onClick={() => {
                    this.setState({ userTeam: 'Brothers' });
                  }}
                  style={{ width: '45%' }}
                >
                  Brothers
                </button>
                <button
                  className='ui inverted button teal'
                  onClick={() => {
                    this.setState({ userTeam: 'Sisters' });
                  }}
                  style={{ width: '45%' }}
                >
                  Sisters
                </button>
              </div>

              {/* <div class='ui selection dropdown'>
                <input
                  type='hidden'
                  name='userTeam'
                  onChange={this.handleChange}
                  required
                />
                <i class='dropdown icon'></i>
                <div class='default text'>Team</div>
                <div class='menu'>
                  <div class='item' data-value='brothers'>
                    Brothers
                  </div>
                  <div class='item' data-value='sisters'>
                    Sisters
                  </div>
                </div>
              </div> */}
              <button
                className='ui button olive'
                onClick={this.saveInfo}
                style={{ height: '30%' }}
              >
                Start Popping!
              </button>
            </div>

            {/* <div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label>
                Name:
                <input
                  type='text'
                  name='name'
                  value={this.state.name}
                  onChange={this.handleChange}
                  required
                />
              </label>
              <label>
                Team:
                <select
                  name='userTeam'
                  id='userTeam'
                  required
                  onChange={this.handleChange}
                >
                  <option value='brothers'>Brothers</option>
                  <option value='sisters'>Sisters</option>
                </select>
              </label>
              <button />
            </div>
          </div> */}
          </Modal>
          <canvas
            id='balloon-pop-canvas'
            style={{
              position: 'fixed',
              width: '100%',
              height: '100%',
              zIndex: -1,
              // width: '500px',
              // height: '500px',
              top: 0
              // left: 0,
              // backgroundColor: 'green'
              // visibility: 'hidden'
            }}
          ></canvas>
          {/* <div style={{ display: 'flex', flexDirection: 'column' }}> */}
          <div
            className='App'
            style={{
              width: '100%',
              backgroundColor: 'aliceblue'
            }}
          >
            <h2
              id='can-pop-time'
              style={{
                color: 'white',
                visibility: 'hidden',
                zIndex: -2,
                position: 'fixed',
                bottom: '10%',
                textAlign: 'center',
                backgroundColor: 'rgb(214, 60, 86)',
                right: '10%',
                borderRadius: '10px',
                padding: '10px'
              }}
            >
              {' '}
            </h2>
            <a
              href='https://youtu.be/4GXt1CegvGg'
              target='_blank'
              rel='noopener noreferrer'
              style={{
                textDecoration: 'none !important',
                position: 'absolute',
                right: '40px',
                fontSize: '2.5rem',
                top: '30px',
                zIndex: 100000
              }}
            >
              <i
                className='info circle icon'
                style={{
                  color: '#1b1c1d'
                }}
              ></i>
            </a>
            {this.props.selectedTeam ? (
              <BalloonGroup balloonPopAnimation={this.balloonPopAnimation} />
            ) : null}
            {/* <canvas
            id='balloon-pop-canvas'
            style={{
              // position: 'absolute',
              width: '100%',
              height: '100%',
              backgroundColor: 'green',
              zIndex: 10000
              // visibility: 'hidden'
            }}
          ></canvas> */}
          </div>
        </div>
        {/* <div className='my-footer'>
          <p>Balloon popping sound from Zapsplat.com</p>
        </div> */}
        {/* </div> */}
      </>
    );
  }
}

const mapState = state => {
  return {
    teams: state.balloonReducer.teams,
    selectedTeam: state.balloonReducer.selectedTeam,
    poppingBalloon: state.balloonReducer.poppingBalloon,
    donated: state.balloonReducer.donated,
    maybePoppedBalloon: state.balloonReducer.maybePoppedBalloon,
    user: state.balloonReducer.user,
    areYouSure: state.balloonReducer.areYouSure,
    timerStart: state.balloonReducer.timerStart
  };
};

export default compose(
  connect(mapState, {
    selectTeam,
    // fetchTeams,
    getCookie,
    setCookie,
    setPopStatus,
    undoDBPop,
    balloonPopInProcess,
    saveInfo,
    setTeamListener
  }),
  firestoreConnect(['group1', 'group2', 'group3'])
)(App);
