import React from 'react';
import './styles/header.css';

class Header extends React.Component {
  // renderTeams = () => {
  //   const teams = this.props.teams.map(team => (
  //     <button
  //       onClick={() => {
  //         this.props.selectTeam(team.id);
  //       }}
  //     >
  //       {team.data.name}
  //     </button>
  //   ));
  //   return teams;
  // };

  render() {
    return (
      <div>
        {/* <img
          alt='logo'
          src='/Charity_Pop_Words_Stroke_Logo.png'
          style={{ height: 100 }}
        /> */}
        {/* <div className='row'>
          <div className='column padding-reset'>
            <div
              className='ui huge message page grid'
              style={{
                backgroundColor: 'midnightblue',
                borderRadius: 0
              }}
            >
              <h1 className='ui huge header' style={{ color: 'azure' }}>
                Charity Pop
              </h1>
              <div style={{ color: 'azure' }}>
                Select your team and pop any balloon to donate!
              </div> */}

        {/* </div>
          </div>
        </div> */}
        <div
          className='ui massive message'
          style={{
            backgroundColor: 'rgb(214, 60, 86)',
            borderRadius: 0
          }}
        >
          <div classname='ui container'>
            <h1
              className='ui huge header'
              style={{ color: 'white', display: 'inline-block' }}
            >
              Charity Pop
            </h1>
            {/* <div className='dropdown'>
              <button className='dropbtn'>Select Team</button>
              <div className='dropdown-content'>
                {this.props.teams ? this.renderTeams() : 'No'}
              </div>
            </div>
            <p style={{ color: 'white' }}>
              Select your team and pop any balloon to donate!
            </p> */}
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
