import _ from 'lodash';
import {
  FETCH_BALLOONS,
  CREATE_BALLOONS,
  UPDATE_BALLOON,
  OPEN_BALLOON,
  REMOVE_POPPING_BALLOON,
  ADD_TO_FEED,
  SELECT_TEAM,
  FETCH_TEAMS,
  UPDATE_TEAM,
  SET_OUT_OF_TIME,
  DONATE,
  COMPLETE_DONATION,
  SET_ARE_YOU_SURE,
  SET_POP_STATUS,
  SAVE_INFO,
  SET_TIMER
} from '../actions/types';

export default (state = {}, action) => {
  switch (action.type) {
    // case CREATE_BALLOONS:
    //   // action.payload.balloons.sort((x, y) =>
    //   //   x.randomPosition === y.randomPosition
    //   //     ? 0
    //   //     : x.randomPosition > y.randomPosition
    //   //     ? 1
    //   //     : -1
    //   // );
    //   return {
    //     ...state,
    //     [`group${action.payload.id}`]: action.payload
    //   };
    // case FETCH_BALLOONS:
    //   return {
    //     ...state,
    //     [`group${action.payload.id}`]: action.payload
    //   };
    // case UPDATE_BALLOON:
    //   // for popping and for when a new balloon is generated
    //   // action.payload.balloons.sort((x, y) =>
    //   //   x.randomPosition === y.randomPosition
    //   //     ? 0
    //   //     : x.randomPosition > y.randomPosition
    //   //     ? 1
    //   //     : -1
    //   // );
    //   return {
    //     ...state,
    //     [`group${action.payload.id}`]: action.payload
    //   };
    case OPEN_BALLOON:
      // for when the user clicks a balloon
      return {
        ...state,
        poppingBalloon: action.payload,
        maybePoppedBalloon: action.payload
      };
    case REMOVE_POPPING_BALLOON:
      // for when the user donates
      let newState = { ...state };
      delete newState.poppingBalloon;
      return newState;
    case ADD_TO_FEED:
      // let feed = [];
      // if (state.feed) {
      //   feed = [...state.feed];
      // }
      return { ...state, feed: action.payload };
    case SELECT_TEAM:
      return {
        ...state,
        selectedTeam: action.payload,
        group1: action.payload.data.group1,
        group2: action.payload.data.group2,
        group3: action.payload.data.group3,
        timeMounted: Date.now()
      };
    case FETCH_TEAMS:
      return {
        ...state,
        teams: action.payload
      };
    case UPDATE_TEAM:
      process.env.NODE_ENV === 'development' && console.log('ummm');
      process.env.NODE_ENV === 'development' && console.log(state.selectedTeam);
      process.env.NODE_ENV === 'development' && console.log(action.payload);
      return {
        ...state,
        selectedTeam: action.payload,
        group1: action.payload.data.group1,
        group2: action.payload.data.group2,
        group3: action.payload.data.group3,
        sisters: action.payload.data.sisters,
        brothers: action.payload.data.brothers
      };
    case SET_OUT_OF_TIME:
      process.env.NODE_ENV === 'development' &&
        console.log('SETTING OUT OF TIME');
      process.env.NODE_ENV === 'development' && console.log(action.payload);
      return {
        ...state,
        outOfTime: action.payload
      };
    case DONATE:
      return {
        ...state,
        donated: true
      };
    case COMPLETE_DONATION:
      let newState2 = { ...state, donated: false };
      delete newState2.outOfTime;
      return newState2;
    case SET_ARE_YOU_SURE:
      return {
        ...state,
        areYouSure: action.payload
      };
    case SET_POP_STATUS:
      return {
        ...state,
        popStatus: action.payload
      };
    case SAVE_INFO:
      return {
        ...state,
        user: action.payload
      };
    case SET_TIMER:
      return {
        ...state,
        timerStart: action.payload
      };
    default:
      return state;
  }
};
