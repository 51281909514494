import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { reduxFirestore, getFirestore } from 'redux-firestore';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import * as serviceWorker from './serviceWorker';

import App from './components/app/App';
import reducers from './reducers';

import firebase from 'firebase/app';
import 'firebase/firestore';
const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;

// Initialize Firebase
const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: 'charity-pop.firebaseapp.com',
  databaseURL: 'https://charity-pop.firebaseio.com',
  projectId: 'charity-pop',
  storageBucket: 'charity-pop.appspot.com',
  messagingSenderId: '669014310363',
  appId: '1:669014310363:web:2a3cf31e0bc482886bbbfe',
  measurementId: 'G-NT4L1RCGQM'
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();

const logger = createLogger({ collapsed: true });
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers,
  composeEnhancers(
    applyMiddleware(
      reduxThunk.withExtraArgument({ getFirestore })
      // logger
    ),
    reduxFirestore(firebase)
  )
);

const rrfProps = {
  firebase,
  config: {},
  dispatch: store.dispatch
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <App />
      </ReactReduxFirebaseProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
