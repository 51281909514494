// import firebase from 'firebase/app';
import {
  CREATE_BALLOONS,
  FETCH_BALLOON,
  FETCH_BALLOONS,
  UPDATE_BALLOON,
  OPEN_BALLOON,
  REMOVE_POPPING_BALLOON,
  DONATE,
  COMPLETE_DONATION,
  SELECT_TEAM,
  FETCH_TEAMS,
  SET_POP_STATUS,
  SET_ARE_YOU_SURE,
  SAVE_INFO,
  ADD_TO_FEED,
  UPDATE_TEAM,
  SET_OUT_OF_TIME,
  SET_TIMER
} from './types';
import { update } from 'lodash';

export const setOutOfTime = outOfTime => {
  return {
    type: SET_OUT_OF_TIME,
    payload: outOfTime
  };
};

export const saveInfo = user => {
  return {
    type: SAVE_INFO,
    payload: user
  };
};

export const donate = () => {
  return {
    type: DONATE
  };
};

export const completeDonation = () => {
  return {
    type: COMPLETE_DONATION
  };
};

export const setAreYouSure = areYouSure => {
  return {
    type: SET_ARE_YOU_SURE,
    payload: areYouSure
  };
};

// export const removePoppingBalloon = () => {
//   return {
//     type: REMOVE_POPPING_BALLOON
//   };
// };

export const selectTeam = teamId => async (
  dispatch,
  getState,
  { getFirestore }
) => {
  const firestore = getFirestore();
  const teamDoc = await firestore.collection('teams').doc(teamId.toString());
  let data;
  await teamDoc.get().then(doc => {
    data = doc.data();
  });
  let newGroup1 = [...data.group1];
  let newGroup2 = [...data.group2];
  let newGroup3 = [...data.group3];
  let count1 = 0;
  let count2 = 0;
  let count3 = 0;
  data.group1.forEach(balloon => {
    if (
      balloon.startedBeingPoppedAt +
        (process.env.NODE_ENV === 'production' ? 601000 : 31000) <
      Date.now()
    ) {
      count1++;
      newGroup1[balloon.dollars - 6].startedBeingPoppedAt = 0;
      newGroup1[balloon.dollars - 6].beingPoppedNow = false;
    }
  });
  data.group2.forEach(balloon => {
    if (
      balloon.startedBeingPoppedAt +
        (process.env.NODE_ENV === 'production' ? 601000 : 31000) <
      Date.now()
    ) {
      count2++;
      // console.log(balloon.dollars - 16);
      // console.log(newGroup2[balloon.dollars - 16]);
      newGroup2[balloon.dollars - 16].startedBeingPoppedAt = 0;
      newGroup2[balloon.dollars - 16].beingPoppedNow = false;
    }
  });
  data.group3.forEach(balloon => {
    if (
      balloon.startedBeingPoppedAt +
        (process.env.NODE_ENV === 'production' ? 601000 : 31000) <
      Date.now()
    ) {
      count3++;
      newGroup3[balloon.dollars - 51].startedBeingPoppedAt = 0;
      newGroup3[balloon.dollars - 51].beingPoppedNow = false;
    }
  });
  if (count1 > 0 || count2 > 0 || count3 > 0) {
    await teamDoc.update({
      group1: newGroup1,
      group2: newGroup2,
      group3: newGroup3
    });
  }
  await dispatch({
    type: SELECT_TEAM,
    payload: { id: teamId, data }
  });
};

export const fetchTeams = () => async (
  dispatch,
  getState,
  { getFirestore }
) => {
  const firestore = getFirestore();
  const teamCollection = firestore.collection('teams');
  const results = await teamCollection.get();
  const teams = results.docs.map(doc => {
    return { id: doc.id, data: doc.data() };
  });
  await dispatch({ type: FETCH_TEAMS, payload: teams });
};

// export const fetchBalloons = group => async (
//   dispatch,
//   getState,
//   { getFirestore }
// ) => {
//   // const firestore = getFirestore();
//   // let groupCollection;
//   // const selectedTeam = getState().teamReducer.selectedTeam;
//   // groupCollection = firestore
//   //   .collection('teams')
//   //   .doc(selectedTeam.id.toString())
//   //   .collection(`group${group}`);
//   // const results = await groupCollection.orderBy('randomPosition').get();
//   // const balloons = results.docs.map(doc => doc.data());
//   // dispatch({
//   //   type: FETCH_BALLOONS,
//   //   payload: { id: group, balloons: balloons }
//   // });
//   const firestore = getFirestore();
//   // let groupCollection;
//   // const selectedTeam = getState().teamReducer.selectedTeam;
//   const teams = await firestore.collection('teams').get();
//   const team = teams.docs[0].data();
//   const balloons = team[`group${group}`];
//   dispatch({
//     type: FETCH_BALLOONS,
//     payload: { id: group, balloons }
//   });
// };

export const createBalloons = group => async (
  dispatch,
  getState,
  { getFirestore }
) => {
  await createBalloons2(group, dispatch, getState, getFirestore);
};

export const popBalloon = group => async (
  dispatch,
  getState,
  { getFirestore }
) => {
  const firestore = getFirestore();
  let teamDoc = firestore
    .collection('teams')
    .doc(getState().balloonReducer.selectedTeam.id.toString());
  let team;
  await teamDoc.get().then(doc => {
    team = doc.data();
  });
  process.env.NODE_ENV === 'development' && console.log(team);
  process.env.NODE_ENV === 'development' && console.log('HERE WE ARE');
  const balloons = team[`group${group}`];
  const poppedBalloons = balloons.filter(balloon => balloon.popped);
  // one step ahead, not?
  if (poppedBalloons.length === (group === 1 ? 10 : group === 2 ? 35 : 50)) {
    process.env.NODE_ENV === 'development' && console.log('HERE WE ARE');
    await createBalloons2(group, dispatch, getState, getFirestore);
  } else {
    process.env.NODE_ENV === 'development' && console.log('LENGTH IS WRONG');
    process.env.NODE_ENV === 'development' &&
      console.log(poppedBalloons.length);
  }
};

// export const popBalloon = (group, balloon) => async (
//   dispatch,
//   getState,
//   { getFirestore }
// ) => {
//   process.env.NODE_ENV === "development" && console.log( 'POPPING BALLOON ACTION');
//   // FIRESTORE
//   const firestore = getFirestore();
//   // const teams = firestore.collection('teams').get();
//   // const teamDoc = teams[0];
//   let teamDoc = firestore
//     .collection('teams')
//     .doc(getState().balloonReducer.selectedTeam.id.toString());
//   let team;
//   await teamDoc.get().then(doc => {
//     team = doc.data();
//   });
//   process.env.NODE_ENV === "development" && console.log( 'TEAM DATA WAS: ');
//   process.env.NODE_ENV === "development" && console.log( team);
//   const balloons = team[`group${group}`];
//   balloons[balloon.dollars - 1] = {
//     ...balloons[balloon.dollars - 1],
//     popped: true,
//     beingPoppedNow: false,
//     poppedBy: getState().donationReducer.user
//   };
//   process.env.NODE_ENV === "development" && console.log( 'POPPPPPED THE BALLOOOON');
//   process.env.NODE_ENV === "development" && console.log( balloons[balloon.dollars - 1]);
//   process.env.NODE_ENV === "development" && console.log( 'NOW BALLOONS OR GROUP ARRAY IS ');
//   process.env.NODE_ENV === "development" && console.log( balloons);
//   firestore
//     .collection('teams')
//     .doc(getState().balloonReducer.selectedTeam.id.toString())
//     .update({
//       [`group${group}`]: balloons
//     })
//     // const groupCollection = firestore
//     //   .collection('teams')
//     //   .doc(getState().teamReducer.selectedTeam.id.toString())
//     //   .collection(`group${group}`);
//     // groupCollection
//     //   .doc(balloon.dollars.toString())
//     //   .update({
//     //     popped: true,
//     //     beingPoppedNow: false,
//     //     poppedBy: getState().donationReducer.user
//     //   })
//     .then(async () => {
//       process.env.NODE_ENV === "development" && console.log( 'updated');
//     })
//     .catch(err => {
//       process.env.NODE_ENV === "development" && console.log( err);
//     });

//   // APP
//   const numNotYetPopped = getState().balloonReducer[`group${group}`].filter(
//     balloon => balloon.popped === false
//   );
//   if (numNotYetPopped.length === 1) {
//     process.env.NODE_ENV === "development" && console.log( 'GOING TO CREATE BALLOONS');
//     // because the state hasn't updated yet to show 0 balloons, but will in a second
//     createBalloons2(group, dispatch, getState, getFirestore);
//   }
// };

export const balloonPopInProcess = (
  group,
  balloon,
  beingPoppedNow,
  canPop = false,
  getRidOfOutOfTime = false,
  getRidOfPoppingBalloon = false
) => async (dispatch, getState, { getFirestore }) => {
  // const firestore = getFirestore();
  // const groupCollection = firestore
  //   .collection('teams')
  //   .doc(getState().teamReducer.selectedTeam.id.toString())
  //   .collection(`group${group}`);
  // await groupCollection
  //   .doc(balloon.dollars.toString())
  //   .update({
  //     beingPoppedNow: beingPoppedNow
  //   })
  //   .then(() => {
  //     process.env.NODE_ENV === "development" && console.log( 'Document successfully updated!');
  //   });
  const firestore = getFirestore();
  let teamDoc = firestore
    .collection('teams')
    .doc(getState().balloonReducer.selectedTeam.id.toString());
  let team;
  await teamDoc.get().then(doc => {
    team = doc.data();
  });
  process.env.NODE_ENV === 'development' && console.log('HERE WE ARE');
  let balloons = team[`group${group}`];
  let minus = group === 1 ? 6 : group === 2 ? 16 : 51;
  balloons[balloon.dollars - minus] = {
    ...balloons[balloon.dollars - minus],
    beingPoppedNow: beingPoppedNow,
    popped: canPop && getState().balloonReducer.donated,
    poppedBy:
      canPop && getState().balloonReducer.donated
        ? getState().balloonReducer.user
        : null,
    poppedAt: canPop && getState().balloonReducer.donated ? Date.now() : null,
    startedBeingPoppedAt: !(canPop && getState().balloonReducer.donated)
      ? Date.now()
      : canPop && getState().balloonReducer.donated
      ? 0
      : balloons[balloon.dollars - minus].startedBeingPoppedAt
  };
  let sisters = team.sisters;
  let brothers = team.brothers;
  process.env.NODE_ENV === 'development' && console.log(sisters);
  process.env.NODE_ENV === 'development' && console.log(brothers);
  if (canPop && getState().balloonReducer.donated) {
    process.env.NODE_ENV === 'development' &&
      console.log(
        'HERE WE ARE, CAN POP AND GET STATE DONATION REDUCER ARE BOTH TRUE'
      );
    if (getState().balloonReducer.user.userTeam === 'Sisters') {
      sisters = sisters + balloon.dollars;
    } else if (getState().balloonReducer.user.userTeam === 'Brothers') {
      brothers = brothers + balloon.dollars;
    } else {
      process.env.NODE_ENV === 'development' && console.log('NEITHER ARE TRUE');
      process.env.NODE_ENV === 'development' &&
        console.log(getState().balloonReducer.user.userTeam);
    }
  } else {
    process.env.NODE_ENV === 'development' && console.log("didn't pop");
    process.env.NODE_ENV === 'development' && console.log(canPop);
    process.env.NODE_ENV === 'development' &&
      console.log(getState().balloonReducer.donated);
  }
  process.env.NODE_ENV === 'development' && console.log(sisters);
  process.env.NODE_ENV === 'development' && console.log(brothers);
  await firestore
    .collection('teams')
    .doc(getState().balloonReducer.selectedTeam.id.toString())
    .update({
      [`group${group}`]: balloons,
      sisters,
      brothers
    })
    .then(() => {
      process.env.NODE_ENV === 'development' &&
        console.log('Document successfully updated!');
    })
    .catch(e => {
      process.env.NODE_ENV === 'development' && console.log(e);
    });
  if (!canPop) {
    setTimeout(
      async () => {
        if (getState().balloonReducer.poppingBalloon) {
          if (getRidOfOutOfTime) {
            dispatch({
              type: SET_OUT_OF_TIME,
              payload: null
            });
          } else {
            dispatch({
              type: SET_OUT_OF_TIME,
              payload: {
                teamId: getState().balloonReducer.poppingBalloon.teamId,
                group: group,
                balloon: balloon
              }
            });
          }
          if (!getState().balloonReducer.donated) {
            let outOfTimeBalloons = team[`group${group}`];
            outOfTimeBalloons[balloon.dollars - minus].startedBeingPoppedAt = 0;
            outOfTimeBalloons[balloon.dollars - minus].beingPoppedNow = false;
            await firestore
              .collection('teams')
              .doc(getState().balloonReducer.selectedTeam.id.toString())
              .update({
                [`group${group}`]: outOfTimeBalloons
              });
          }
        }
      },
      process.env.NODE_ENV === 'production' ? 601500 : 31500
    );
    await dispatch({
      type: OPEN_BALLOON,
      payload: {
        teamId: getState().balloonReducer.selectedTeam.id,
        group: group,
        balloon: balloon
      }
    });
  } else {
    await dispatch({
      type: REMOVE_POPPING_BALLOON,
      payload: null
    });
  }
  if (getRidOfOutOfTime) {
    dispatch({
      type: SET_OUT_OF_TIME,
      payload: null
    });
  }
  if (getRidOfPoppingBalloon) {
    dispatch({
      type: REMOVE_POPPING_BALLOON,
      payload: null
    });
  }
};

export const undoDBPop = () => async (dispatch, getState, { getFirestore }) => {
  // This method runs before refresh
  // so we don't need to save anything in state
  // but if we did, then dispatch here saying poppingBalloon is undefined;
  //   const firestore = getFirestore();
  //   const balloonDoc = firestore
  //     .collection('teams')
  //     .doc(getState().balloonReducer.poppingBalloon.teamId.toString())
  //     .collection(`group${getState().balloonReducer.poppingBalloon.group}`)
  //     .doc(getState().balloonReducer.poppingBalloon.balloon.dollars.toString());
  //   balloonDoc
  //     .update({ beingPoppedNow: false })
  //     .then(() => {
  //       process.env.NODE_ENV === "development" && console.log( 'Document successfully updated!');
  //     })
  //     .catch(error => {
  //       // The document probably doesn't exist.
  //       console.error('Error updating document: ', error);
  //     });
  // };
  const firestore = getFirestore();
  let teamDoc = firestore
    .collection('teams')
    .doc(getState().balloonReducer.selectedTeam.id.toString());
  let team;
  await teamDoc.get().then(doc => {
    team = doc.data();
  });
  const group = `group${getState().balloonReducer.poppingBalloon.group}`;
  const balloons = team[group];
  const balloon = getState().balloonReducer.poppingBalloon.balloon;
  let minus = group === 1 ? 6 : group === 2 ? 16 : 51;
  balloons[balloon.dollars - minus] = {
    ...balloons[balloon.dollars - minus],
    beingPoppedNow: false
  };
  await firestore
    .collection('teams')
    .doc(getState().balloonReducer.selectedTeam.id.toString())
    .update({
      [group]: balloons
    })
    .then(() => {
      process.env.NODE_ENV === 'development' &&
        console.log('Document successfully updated!');
    })
    .catch(e => {
      process.env.NODE_ENV === 'development' && console.log(e);
    });
};

const createBalloons2 = async (group, dispatch, getState, getFirestore) => {
  const firestore = getFirestore();
  let teamDoc = firestore
    .collection('teams')
    .doc(getState().balloonReducer.selectedTeam.id.toString());
  let teamData;
  await teamDoc.get().then(doc => {
    teamData = doc.data();
  });
  // const balloons = teamData[`group${group}`];
  process.env.NODE_ENV === 'development' && console.log('TEAM DATA');
  process.env.NODE_ENV === 'development' && console.log(teamData);
  await teamDoc
    .update({
      [`group${group}Rounds`]: teamData[`group${group}Rounds`] + 1
    })
    .then(() => {
      process.env.NODE_ENV === 'development' && console.log('team doc updated');
    })
    .catch(err => {
      process.env.NODE_ENV === 'development' && console.log(err);
    });
  switch (group) {
    case 1:
      // let count1 = 1;
      // // Get a new write batch
      // const batch1 = firestore.batch();
      // while (count1 < 11) {
      //   createBalloon(count1, groupCollection, batch1);
      //   count1++;
      // }
      // // Commit the batch
      // batch1.commit().then(() => {
      //   process.env.NODE_ENV === "development" && console.log( 'BATCH 1 COMMITTED');
      // });
      await createBalloonsInDB(1, teamDoc);
      break;
    case 2:
      // let count2 = 1;
      // // Get a new write batch
      // const batch2 = firestore.batch();
      // while (count2 < 51) {
      //   createBalloon(count2, groupCollection, batch2);
      //   count2++;
      // }
      // // Commit the batch
      // batch2.commit().then(() => {
      //   process.env.NODE_ENV === "development" && console.log( 'BATCH 2 COMMITTED');
      // });
      await createBalloonsInDB(2, teamDoc);
      break;
    case 3:
      // let count3 = 1;
      // // Get a new write batch
      // const batch3 = firestore.batch();
      // while (count3 < 101) {
      //   createBalloon(count3, groupCollection, batch3);
      //   count3++;
      // }
      // // Commit the batch
      // batch3.commit().then(() => {
      //   process.env.NODE_ENV === "development" && console.log( 'BATCH 3 COMMITTED');
      // });
      await createBalloonsInDB(3, teamDoc);
      break;
    default:
      break;
  }
};

// const updateBalloon = async (dispatch, getState, group, balloon) => {
//   const balloons = getState().balloonReducer[`group${group}`].balloons;
//   const foundBalloon = balloons.findIndex(x => x.dollars === balloon.dollars);
//   let updatedBalloons = balloons;
//   updatedBalloons[foundBalloon] = balloon;
//   dispatch({
//     type: UPDATE_BALLOON,
//     payload: { id: group, balloons: updatedBalloons }
//   });
// };

// export const setListener = group => async (
//   dispatch,
//   getState,
//   { getFirestore }
// ) => {
//   const firestore = getFirestore();
//   let groupCollection;
//   const selectedTeam = getState().balloonReducer.selectedTeam;
//   groupCollection = firestore
//     .collection('teams')
//     .doc(selectedTeam.id.toString())
//     .collection(`group${group}`);
//   groupCollection.onSnapshot(querySnapshot => {
//     querySnapshot.docChanges().forEach(change => {
//       process.env.NODE_ENV === "development" && console.log( change);
//       if (change.type === 'modified') {
//         process.env.NODE_ENV === "development" && console.log( 'Modified balloon: ', change.doc.data());
//         updateBalloon(dispatch, getState, group, change.doc.data());
//         if (change.doc.data().poppedBy) {
//           dispatch({
//             type: ADD_TO_FEED,
//             payload: { group, balloon: change.doc.data() }
//           });
//         }
//       }
//     });
//   });
// };

export const setTeamListener = () => async (
  dispatch,
  getState,
  { getFirestore }
) => {
  const firestore = getFirestore();
  firestore.collection('teams').onSnapshot(querySnapshot => {
    querySnapshot.docChanges().forEach(async change => {
      process.env.NODE_ENV === 'development' && console.log(change);
      if (change.type === 'modified') {
        process.env.NODE_ENV === 'development' &&
          console.log('TEAAAAM UPPPDATEEEEDDDD');
        process.env.NODE_ENV === 'development' && console.log(change);
        process.env.NODE_ENV === 'development' &&
          console.log('Modified team: ', change.doc.data());
        await dispatch({
          type: UPDATE_TEAM,
          payload: { id: change.doc.id, data: change.doc.data() }
        });
        let feed = [];
        let group1 = change.doc.data().group1;
        group1.forEach(balloon => {
          if (balloon.poppedBy) {
            if (balloon.poppedAt > getState().balloonReducer.timeMounted) {
              feed.push({ group: 1, balloon });
            }
          }
        });
        let group2 = change.doc.data().group2;
        group2.forEach(balloon => {
          if (balloon.poppedBy) {
            if (balloon.poppedAt > getState().balloonReducer.timeMounted) {
              feed.push({ group: 2, balloon });
            }
          }
        });
        let group3 = change.doc.data().group3;
        group3.forEach(balloon => {
          if (balloon.poppedBy) {
            if (balloon.poppedAt > getState().balloonReducer.timeMounted) {
              feed.push({ group: 3, balloon });
            }
          }
        });

        if (feed.length > 0) {
          feed.sort((x, y) =>
            x.balloon.poppedAt === y.balloon.poppedAt
              ? 0
              : x.balloon.poppedAt > y.balloon.poppedAt
              ? 1
              : -1
          );
          await dispatch({
            type: ADD_TO_FEED,
            payload: feed
          });
        }
      }
    });
  });
};

export const setCookie = (cname, cvalue, exdays) => async (
  dispatch,
  getState,
  { getFirestore }
) => {
  process.env.NODE_ENV === 'development' && console.log('setting cookie');
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  switch (cname) {
    case 'popStatus':
      process.env.NODE_ENV === 'development' && console.log('CASE POP STATUS');
      dispatch({ type: SET_POP_STATUS, payload: cvalue });
      const canPopAt = 'canPopAt';
      if (cvalue === false) {
        process.env.NODE_ENV === 'development' &&
          console.log('cvalue is false!!');
        document.cookie =
          canPopAt + '=' + new Date().getTime() + ';' + expires + ';path=/';
        process.env.NODE_ENV === 'development' &&
          console.log('we have just set canPopAt');
        process.env.NODE_ENV === 'development' && console.log(canPopAt);
        dispatch({ type: SET_TIMER, payload: new Date().getTime() });
        setTimeout(
          () => {
            process.env.NODE_ENV === 'development' &&
              console.log(
                'timeout is now complete, popStatus is true, canPopAt is expired'
              );
            document.cookie = cname + '=' + true + ';' + expires + ';path=/';
            document.cookie = canPopAt + '= ;' + expires + ';path=/';
            dispatch({ type: SET_POP_STATUS, payload: true });
            dispatch({
              type: REMOVE_POPPING_BALLOON,
              payload: null
            });
            dispatch({ type: SET_TIMER, payload: null });
          },
          process.env.NODE_ENV === 'production' ? 3600500 : 60500
        );
        // in case the user leaves the page before setTimeout can finish
        const firestore = getFirestore();
        // let balloonDoc;
        const poppingBalloon = getState().balloonReducer.poppingBalloon;
        if (poppingBalloon) {
          let teamDoc = firestore
            .collection('teams')
            .doc(getState().balloonReducer.selectedTeam.id.toString());
          let teamData;
          await teamDoc.get().then(doc => {
            teamData = doc.data();
          });
          let newBalloons = teamData[`group${poppingBalloon.group}`];
          const foundBalloonIndex = newBalloons.findIndex(
            balloon => balloon.dollars === poppingBalloon.balloon.dollars
          );
          newBalloons[foundBalloonIndex].beingPoppedNow = false;
          await teamDoc.update({
            [`group${poppingBalloon.group}`]: newBalloons
          });
        }
        // if (poppingBalloon) {
        //   process.env.NODE_ENV === "development" && console.log( 'FOUND THE BALLOON BEING POPPED');
        //   balloonDoc = firestore
        //     .collection('teams')
        //     .doc(poppingBalloon.teamId.toString())
        //     .collection(`group${poppingBalloon.group}`)
        //     .doc(poppingBalloon.balloon.dollars.toString());
        //   balloonDoc
        //     .update({ beingPoppedNow: false })
        //     .then(() => {
        //       process.env.NODE_ENV === "development" && console.log( 'Document successfully updated!');
        //     })
        //     .catch(error => {
        //       // The document probably doesn't exist.
        //       console.error('Error updating document: ', error);
        //     });
        // }
      } else {
        document.cookie = canPopAt + '= ;' + expires + ';path=/';
      }
      break;
    default:
      break;
  }
};

export const setPopStatus = cvalue => async dispatch => {
  await dispatch({ type: SET_POP_STATUS, payload: cvalue });
};

export const getCookie = cname => dispatch => {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
};

// const createBalloon = async (count, groupCollection, batch) => {
//   let colors = [
//     'red',
//     'midnightblue',
//     'gold',
//     'limegreen',
//     'purple',
//     'darkorange',
//     'aqua',
//     'deeppink',
//     'slategrey'
//   ];
//   const balloon = {
//     beingPoppedNow: false,
//     color: colors[Math.floor(Math.random() * colors.length)],
//     dollars: count,
//     popped: false,
//     randomPosition: Math.random(),
//     randomSize: Math.random()
//   };
//   batch.set(groupCollection.doc(count.toString()), balloon);
//   return balloon;
// };

const createBalloonsInDB = async (group, teamDoc) => {
  let colors = [
    'red',
    'midnightblue',
    'gold',
    'limegreen',
    'purple',
    'darkorange',
    'aqua',
    'deeppink',
    'slategrey'
  ];
  process.env.NODE_ENV === 'development' &&
    console.log('CREATING BALLOON IN DB');
  let balloons = [];
  let count = group === 1 ? 6 : group === 2 ? 16 : 51;
  while (count < (group === 1 ? 16 : group === 2 ? 51 : 101)) {
    const balloon = {
      beingPoppedNow: false,
      color: colors[Math.floor(Math.random() * colors.length)],
      dollars: count,
      popped: false,
      poppedAt: null,
      poppedBy: null,
      randomPosition: Math.random(),
      randomSize: Math.random(),
      startedBeingPoppedAt: 0
    };
    balloons.push(balloon);
    count++;
  }
  process.env.NODE_ENV === 'development' && console.log(balloons);
  await teamDoc.update({
    [`group${group}`]: balloons
  });
  process.env.NODE_ENV === 'development' && console.log('done updating');
  // batch.set(groupCollection.doc(count.toString()), balloon);
  // return balloons;
};
